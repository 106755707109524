import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"
import useWindowSize from "../utilt/useWindowSize"

//CSS
import { top_dfc_wrapper } from "./topDfc.module.css"
import { top_dfc_desc } from "./topDfc.module.css"

let randNum = Math.random();


const TopDfc = (props) => {
  //画像データ取得
  const data = useStaticQuery(graphql`query QueryTop_dfs {
    allFile(
      filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "top_dfc"}}
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
  }
  `)
  //1 取得した画像データを配列化
  const images = data.allFile.edges.map(n => n.node);
  const iLen = images.length;
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }

  let randImage = images[Math.floor(randNum * iLen)];
  const changeRandNum = () => randNum = Math.random();

  return (
    <div style={{
      margin: `0 auto 52px`
    }}>
      <div
        className={top_dfc_wrapper}
        style={{
          width: `${Math.min((winSize.height - 120) * 1.33, Math.min(winSize.width, 640))}px`,
          margin: `${(winSize.height < 600 || winSize.width) < 640 ? 0 : 16}px auto 0`
        }}
        onClick={() => changeRandNum()}
        onKeyDown={() => { }}
      >
        <GatsbyImage
          image={randImage.childImageSharp.gatsbyImageData}
          alt={randImage.base.split(".")[0]}
        />
      </div>
      <p
        className={top_dfc_desc}
        style={{
          margin: `${(winSize.height < 600 || winSize.width) < 640 ? 0 : -4}px auto 0`
        }}>
        No.{Number(randImage.base.split(".")[0])} from "100000 doodles"
      </p>
    </div>
  )
}

export default TopDfc;