import { Link } from "gatsby"
import React from 'react';

//CSS
import {footer} from "./footer.module.css"
import {footerA} from "./footer.module.css"

const Footer = (props) => {
    return (
       <footer
         className={footer}
         style={{
           backgroundColor:`${props.footerBaseColor}`,
           color:`${props.footerColor}`,
           marginTop: `${props.marginTop}`
         }}
       >
         © {new Date().getFullYear()}
         {` `}
         <Link
           to="/"
           className={footerA}
           style={{
           color:`${props.footerColor}`
         }}
         >
           DRAWINGHELL
         </Link>
       </footer>
 )}

export default Footer
