import { useState, useEffect } from 'react';

function useWindowSize() {
    function getSize() {
      if (typeof window !== 'undefined') {
        return {
          width: window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth,
          height: window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight,
        };
      } else {
        return {
          width: 640,
          height: 948 /* 758 + 190(上部余白ぶん)*/
        }
      }
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
      let timeoutId = null;
      const handleResize = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setWindowSize(getSize());
        }, 50);
      };

      window.addEventListener('load', handleResize);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('load', handleResize);
        window.removeEventListener('resize', handleResize);
      }
    }, []); //一回のみ

    return windowSize;
  }

  export default useWindowSize