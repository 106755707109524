import React from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import TopDfc from "../components/topDfc"

import getLanguage from "../utilt/getLangueage"
import useWindowSize from "../utilt/useWindowSize"
//import seoImg from "../images/seo/seo_site.jpg"


const IndexPage = (props) => {
  const lang = getLanguage();
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }
  return <>
    <Seo
      title="Home"
      description={lang !== "ja" ?
        "The Works of Mizuno Ryo(３)" :
        "水野 亮（３）の作品とその他"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_site.jpg"
      pathname="/"
    />
    <Layout
      winSize={winSize || props.size || { width: 640, height: 948 }}
    >
      <TopDfc></TopDfc>

    </Layout>
  </>;
}

export default IndexPage

