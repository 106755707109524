import React from 'react';
import PropTypes from "prop-types"

//CSS
import { header } from "./layout.module.css"
//import { nav } from "./layout.module.css"
import { main } from "./layout.module.css"
import { dotPulse } from "./layout.module.css"

import Header from "./header"
import Footer from "./footer"


import favicon from '../images/favicon.ico'
import { Helmet } from 'react-helmet'


const Layout = (props) => {
  let footerBaseColor = "#ffffff"
  if (typeof props.footerBaseColor !== "undefined") footerBaseColor = props.footerBaseColor;

  let footerMargintop = "0.75rem"
  if (typeof props.footerMargintop !== "undefined") footerMargintop = props.footerMargintop;

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
        {!props.hideHeader
          ? <Header
            winSize={props.winSize}
            checked={props.checked}
            className={header}
          ></Header>
          : <></>}
        <div>
          <main
            className={main}
          >
            {props.children}</main>
          <Footer
            footerBaseColor={footerBaseColor}
            footerColor="#666666"
            marginTop={footerMargintop}
          ></Footer>
        </div>

      <div
        key={`loader`}
        id="___loader"
        style={{
          alignItems: "center",
          backgroundColor: "#ffffff",
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 100,
          transition: "0.4s",
          display: "flex",
          justifyContent: "center",
          //alignItems: "center",
        }}
      >
        <dotPulse className={dotPulse}></dotPulse>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout


/*
        {!props.hideNavs
          ? <Navs
            checked={props.checked}
            className={nav}
          />
          : <></>}
*/