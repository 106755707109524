import { Link } from "gatsby"
import React from 'react';

//CSS
import { headerGround } from "./header.module.css"
import { header } from "./header.module.css"
import { logo } from "./header.module.css"
import { logoImg } from "./header.module.css"
import { navs } from "./header.module.css"
import { navChecked } from "./header.module.css"
import { nav } from "./header.module.css"
import { navCheckedParts } from "./header.module.css"


import SiteLogo from "../images/siteLogo.png"
import SiteLogo2 from "../images/siteLogo2.png"
import Dummy from "../images/dummy.png"


const Header = (props) => {
  const IconMinSize = 300;
  const fontSize = 1.2;//0.85 + Math.min(0.3 * (props.width / 960), 0.3) || 1;
  return (
    <header>
      <div
        className={headerGround}
      >
        <div
          className={header}
        >
          <Link
            to="/"
          >
            <div
              className={logo}
              style={{
                marginLeft: `${0.5 + 2 * Math.min((props.winSize.width / 1200) ** 2, 1) || 1}rem`
              }}
            >
              <img
                src={SiteLogo}
                srcSet={` ${SiteLogo} 1280w`}
                sizes={"75vw"}
                alt="site logo"
                className={logoImg}
              />
              <img
                src={props.width > IconMinSize ? SiteLogo2 : Dummy}
                srcSet={` ${props.winSize.width > IconMinSize ? SiteLogo2 : Dummy} 1280w`}
                sizes={"50vw"}
                alt="site logo"
                className={logoImg}
              />
            </div>
          </Link>
        </div>
        <div
          className={navs}
        >
          <Link
            to="/about"
            className={props.checked === `about` ? navChecked : nav}
            style={{
              //marginRight: `${navMargin}rem`,
              fontSize: `${fontSize}rem`
            }}
          >
            about
          </Link>
          <Link
            to="/works"
            className={`${props.checked === `works` ? navChecked : nav} 
                ${props.checked === `worksParts` ? navCheckedParts : nav} `}
            style={{
              //marginRight: `${navMargin}rem`,
              fontSize: `${fontSize}rem`
            }}
          >
            works
          </Link>
        </div >
      </div>
    </header >
  )
}

export default Header
